/** @jsx jsx */
import { jsx } from "theme-ui";
import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "../state/selectors";
import SignInButton from "./SignInButton";
import Login from "./Login";
import SignOutButton from "./SignOutButton";

export default function ensureSignedInConditional(
  Component,
  {
    conditions = (/* r */) => true,
    msgUnAuthorized = null,
    msgUnSignedIn = null,
    showSignInButton = false,
    showSignInWidget = false,
  }
) {
  return function EnhancedComponent(props) {
    const srcmProfile = useSelector(selectorSrcmProfile);

    if (srcmProfile) {
      if (conditions(srcmProfile)) {
        return <Component {...props} srcmProfile={srcmProfile} />;
      }
      return (
        <div>
          {typeof msgUnAuthorized === "function" ? (
            msgUnAuthorized(srcmProfile, SignOutButton)
          ) : (
            <div>
              {msgUnAuthorized} <br />
              <br />
              <SignOutButton btnText="Sign-in as another user" />
            </div>
          )}
        </div>
      );
    }
    if (showSignInWidget) {
      return <Login />;
    }
    return (
      <div>
        {showSignInButton ? (
          <SignInButton
            btnText={
              typeof showSignInButton === "string"
                ? showSignInButton
                : "Sign-In"
            }
          />
        ) : (
          msgUnSignedIn
        )}
      </div>
    );
  };
}
