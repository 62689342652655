import React from "react";

import PropTypes from "prop-types";

import { Link } from "gatsby";
import { Avatar, SignInButton } from "gatsby-plugin-hfn-profile/auth";
import { Container, Navbar, Nav } from "react-bootstrap";
import { selectorSrcmProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { useSelector } from "react-redux";
import { StaticImage } from "gatsby-plugin-image";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { dayVisitorLink } = getMandatoryEnv(["dayVisitorLink"]);

const Header = ({ isSticky }) => {
  const srcmProfile = useSelector(selectorSrcmProfile);

  return (
    <header
      id="header"
      className={`header-section ${isSticky ? "" : "sticky"}`}
    >
      <Navbar expand="xl" variant="dark">
        <Container>
          <div className="logo-wrapper">
            <Link to="/" style={{ color: "transparent" }}>
              <Navbar.Brand to="/" className="logo-section">
                <span className="top">Kanha</span>
                <span className="bottom">Shanti Vanam</span>
              </Navbar.Brand>
            </Link>
            <div className="d-flex" style={{ gap: "20px" }}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <div className="avatar-md">
                <SignInButton btnText="Log in" />
                <Avatar />
              </div>
            </div>
          </div>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <ul className="main-menu">
                {/* <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/"
                    className="nav-link nlb"
                  >
                    home
                  </Link>
                </li> */}
                <li>
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    about
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/about"
                        className="nav-link"
                      >
                        About Kanha
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/gallery"
                        className="nav-link"
                      >
                        Gallery
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/events-listing"
                    className="nav-link nlb"
                  >
                    Events
                  </Link>
                </li>
                <li>
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    visit kanha
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        activeClassName="nav_link_active"
                        href={dayVisitorLink}
                        target="_blank"
                        className="nav-link"
                        rel="noreferrer"
                      >
                        Day Visit
                      </a>
                    </li>
                    {/* <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/events"
                        className="nav-link"
                      >
                        Attend events
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/visitors"
                        className="nav-link"
                      >
                        Register your visit
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/book-a-stay"
                        className="nav-link"
                      >
                        Stay facilities
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/service"
                    className="nav-link nlb"
                  >
                    Facilities
                  </Link>
                </li>
                <li className="nlb_container">
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    initiatives
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/green-initiatives"
                        className="nav-link"
                      >
                        Green Initiatives
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/blue-initiatives"
                        className="nav-link"
                      >
                        Blue Initiatives
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="nav-link"
                    href="https://donations.heartfulness.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    donate
                  </a>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/shop"
                    className="nav-link nlb"
                  >
                    shop
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/contact-us"
                    className="nav-link nlb"
                  >
                    contact us
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    to="/kmc"
                    className="nav-link nlb kmc-logo-kanhaweb"
                  >
                    <StaticImage
                      src="../assets/images/kmc-images/60-60-KMC.png"
                      alt="KMC logo"
                      className="kmclogo-kanhaweb"
                    />
                  </Link>
                </li>
                {/* // <li>
                  //   <Link activeClassName="nav_link_active" to="/" className="small-links">
                  //     volunteer
                  //   </Link>
                  // </li> */}
                <li className="register-link">
                  {srcmProfile?.id && (
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        to="/my-registrations"
                        className="small-links nlb"
                      >
                        My Registrations
                      </Link>
                    </li>
                  )}
                  <li className="avatar">
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "16px",
                      }}
                    >
                      <SignInButton btnText="Log in" />
                      <Avatar />
                    </div>
                  </li>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.defaultProps = {
  isSticky: `false`,
};

Header.propTypes = {
  isSticky: PropTypes.bool,
};

export default Header;
